import React, { Component } from 'react'
import Typing from 'react-typing-animation'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { Hero, Heading, Section, Container } from 'react-bulma-components'

export default class Intro extends Component {
  render () {
    return (
      <Section id='intro' className='intro-box has-text-white has-text-centered' size='medium'>
        <Hero>
          <Hero.Body>
            <Container fluid>
              <Heading className='has-text-white intro-box__header' spaced>
                <Typing cursorClassName='intro-box__typing-cursor'>
                  <span>Hi, I'm Emilio Ybarra.</span>
                </Typing>
              </Heading>
              <Heading className='has-text-white' subtitle spaced renderAs='p'>
                Welcome to my personal website.
              </Heading>
              <AnchorLink href='#about' aria-label='Click to scroll down to the about section'>
                <FontAwesomeIcon className='pulse-button' icon={faAngleDown} size='3x' />
              </AnchorLink>
            </Container>
          </Hero.Body>
        </Hero>
      </Section>
    )
  }
}
