import React, { Component } from 'react'
import Intro from './Intro'
import About from './About'
import Skills from './Skills'
import Foot from './Footer'
import Resume from './Resume'

export default class Content extends Component {
  render () {
    return (
      <div>
        <Intro />
        <About />
        <Resume />
        <Skills />
        <Foot />
      </div>
    )
  }
}
