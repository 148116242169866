import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { Footer, Content, Container } from 'react-bulma-components'

export default class Foot extends Component {
  render () {
    return (
      <Footer className='has-background-dark' style={{paddingBottom: '3rem'}}>
        <Container fluid>
          <Content className='has-text-grey-lighter' style={{ textAlign: 'center' }}>
            <span>Emilio Ybarra</span>&nbsp;&nbsp;|&nbsp;&nbsp;
            <span>Contact me:</span>&nbsp;<a href="mailto:emilio.ybarra@outlook.com"><i>emilio.ybarra@outlook.com</i></a>&nbsp;&nbsp;|&nbsp;&nbsp;
            <a id='github-link' href='https://github.com/eybarra10' target='_blank' rel='noopener noreferrer' aria-label='Github account'>
              <FontAwesomeIcon size='sm' icon={faGithub} />
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a id='linkedin-link' href='https://www.linkedin.com/in/emilioybarra/' target='_blank' rel='noopener noreferrer' aria-label='LinkedIn profile'>
              <FontAwesomeIcon size='sm' icon={faLinkedinIn} />
            </a>
          </Content>
        </Container>
      </Footer>
    )
  }
}
