import React, { Component } from 'react'
import { Heading, Columns, Content } from 'react-bulma-components'

export default class Work extends Component {
  render () {
    return (
      <div>
        <Columns breakpoint='tablet'>
          <Columns.Column size={3}>
            <Heading className='resume-header header-underline' size={4}>Work Experience</Heading>
          </Columns.Column>
          <Columns.Column className='resume-content' size={9}>
            <Content className='resume-content__item'>
              <Heading size={3}>Cardinal Glass Industries</Heading>
              <Heading className='resume-content__item-subheader' subtitle size={5}>
                <i>Hood River, Oregon | June '11 - Sept.'12</i>
              </Heading>
              <p>
                Handled and carried all types of glass around the factory, I also managed the computer
                and heavy equipment at one operating table, and I trained new coming employees as well.
              </p>
            </Content>
            <Content className='resume-content__item'>
              <Heading size={3}>Juanita’s Fine Foods Inc.</Heading>
              <Heading className='resume-content__item-subheader' subtitle size={5}>
                <i>Hood River, Oregon | Sept. '12 - July. '14</i>
              </Heading>
              <p>
                Delivered produce and goods to markets almost daily, also assisted the warehouse manager
                to manage the shipments and also organize the warehouse items.
              </p>
            </Content>
            <Content className='resume-content__item'>
              <Heading size={3}>Volunteer Social Worker and Care-Taker</Heading>
              <Heading className='resume-content__item-subheader' subtitle size={5}>
                <i>Solingen, Germany | Sept. '14 - Aug. '16</i>
              </Heading>
              <p>
                Volunteer social worker and care-taker at a senior center/retirement home in Solingen,
                Josef-Haus Solingen, Germany.
              </p>
            </Content>
            <Content className='resume-content__item'>
              <Heading size={3}>Ausbildung zum Fachinfomatiker für Anwendungsentwicklung</Heading>
              <Heading className='resume-content__item-subheader' subtitle size={5}>
                <i>Cologne, Germany | Oct. '15 - May '18</i>
              </Heading>
              <p>
                Apprenticeship/Training as IT specialist for application development, and working at Hivemind
                Technologies AG in Cologne, Germany.
              </p>
            </Content>
            <Content className='resume-content__item'>
              <Heading size={3}>Junior Web / Frontend Developer</Heading>
              <Heading className='resume-content__item-subheader' subtitle size={5}>
                <i>Cologne, Germany | Nov. '18 - Present</i>
              </Heading>
              <p>
                Working with React.js and Craft CMS for customer websites and projects at Hoods GmbH in Cologne, Germany.
              </p>
            </Content>
          </Columns.Column>
        </Columns>
      </div>
    )
  }
}
