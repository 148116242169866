import React, { Component } from 'react'
import { Heading, Content, Columns } from 'react-bulma-components'

export default class Education extends Component {
  render () {
    return (
      <div className='section-item'>
        <Columns breakpoint='tablet'>
          <Columns.Column size={3}>
            <Heading className='resume-header header-underline' size={4}>Education</Heading>
          </Columns.Column>
          <Columns.Column className='resume-content' size={9}>
            <Content className='resume-content__item'>
              <Heading size={3}>Hood River Valley High School</Heading>
              <Heading className='resume-content__item-subheader' subtitle size={5}>
                <i>Hood River, Oregon | Sept. '06 - June '10</i>
              </Heading>
              <p>Graduated with a High School Diploma</p>
            </Content>
            <Content className='resume-content__item'>
              <Heading size={3}>Friedrich-Albert-Lange-Schule</Heading>
              <Heading className='resume-content__item-subheader' subtitle size={5}>
                <i>Solingen, Germany | Aug. '10 - June '11</i>
              </Heading>
              <p>Exchange Student Year</p>
            </Content>
            <Content className='resume-content__item'>
              <Heading size={3}>Columbia Gorge Community College</Heading>
              <Heading className='resume-content__item-subheader' subtitle size={5}>
                <i>Hood River, Oregon | Sept. '12 - Nov. '12</i>
              </Heading>
              <p>General Studies: Algebra, Writing, Reading</p>
            </Content>
            <Content className='resume-content__item'>
              <Heading size={3}>ITT-Tech Technical Institute</Heading>
              <Heading className='resume-content__item-subheader' subtitle size={5}>
                <i>Portland, Oregon | Sept. '13 - Dec. '14</i>
              </Heading>
              <p>
                Associate of Applied Science
                <br/>
                Major: Network Systems Administration/Information Systems Administration
              </p>
            </Content>
            <Content className='resume-content__item'>
              <Heading size={3}>Georg-Simon-Ohm-Berufskolleg</Heading>
              <Heading className='resume-content__item-subheader' subtitle size={5}>
                <i>Colonge, Germany | Oct. '15 - May '18</i>
              </Heading>
              <p>Berufsschule</p>
            </Content>
          </Columns.Column>
        </Columns>
        <hr className='has-background-grey-darker' />
      </div>
    )
  }
}
