import App from './App'
import React from 'react'
import './styles/index.css'
import ReactDOM from 'react-dom'
import 'font-awesome/css/font-awesome.css'
import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

registerServiceWorker()
