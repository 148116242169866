import React, { Component } from 'react'
import photo from '../images/emilio.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { Heading, Section, Content, Button, Container } from 'react-bulma-components'
import Fade from 'react-reveal/Fade'

export default class About extends Component {
  render () {
    return (
      <Section id='about' className='section--padding has-background-grey-darker' size='medium'>
        <Container fluid>
          <Fade>
            <Heading className='has-text-white'>A Little About Me</Heading>
            <hr />
            <Content className='has-text-white'>
              <figure className='resume-photo image is-128x128 is-pulled-left'>
                <img className='is-rounded' src={photo} alt='128x128' />
              </figure>
              <p>
                Hi, I'm Emilio Ybarra and I'm originally from Hood River, Oregon. I am also quite new into the web
                development environment. My interest in programming started when I did an apprenticeship in web development
                for three years at Hivemind Technologies AG in Cologne, Germany and I really enjoyed it. I've also lived
                in Germany for about almost five years now with my German girlfriend, whom I met in the States. I also don't
                plan on moving back to the States anytime soon or at all, because I really love living Germany!
              </p>
              <p>
                I've had about three years of experience working with React.js so far at work on internal company projects
                or for customer websites, and to this day, I program mostly with React, which is my main focus and I really enjoy working
                with anyway! I have quite a bit of experience in HTML/HTML5 and CSS/SCSS, but I am also always still learning
                new things every day.
              </p>
              <p>
                This website is my first live project and my first personal website that I build completely with React,
                it was very fun to build! I also plan to build my future projects with React.
              </p>
              <br />
              <h5 className='has-text-white'>Download or save the PDF version of my resume:</h5>
              <div className='buttons-container'>
                <Button id='english-resume' color='white' renderAs='a' target='_blank' href='EYbarra-Resume-2019.pdf'>
                  English PDF&nbsp;<FontAwesomeIcon icon={faCloudDownloadAlt} />
                </Button>
                <Button id='german-resume' color='white' renderAs='a' target='_blank' href='EYbarra-Lebenslauf-2019.pdf'>
                  German PDF&nbsp;<FontAwesomeIcon icon={faCloudDownloadAlt} />
                </Button>
              </div>
            </Content>
          </Fade>
        </Container>
      </Section>
    )
  }
}
