import React, { Component } from 'react'
import logo from '../images/e-logo.png'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Image, Navbar, Container } from 'react-bulma-components'

export default class Navigation extends Component {
  constructor () {
    super()

    this.state = {
      isActive: false
    }

    this.toggleActiveState = this.toggleActiveState.bind(this)
  }

  toggleActiveState () {
    this.setState({
      isActive: !this.state.isActive
    })
  }

  render () {
    const { isActive } = this.state
    return (
      <Navbar color='black' fixed='top' active={isActive}>
        <Container fluid>
          <Navbar.Brand>
            <AnchorLink aria-label='Click logo to scroll to the top' className='navbar-item navibar-header' renderas='div' href='#intro'>
              <Image className='navibar-logo' alt='35x35' src={logo} />
            </AnchorLink>
            <Navbar.Burger id='menu-button' active={isActive} onClick={this.toggleActiveState} />
          </Navbar.Brand>
          <Navbar.Menu active={isActive}>
            <Navbar.Container position='end'>
              <AnchorLink aria-label='About section' className='navbar-item navbar-item--menu-link' href='#about' onClick={this.toggleActiveState}>About</AnchorLink>
              <AnchorLink aria-label='Resume section' className='navbar-item navbar-item--menu-link' href='#resume' onClick={this.toggleActiveState}>Resume</AnchorLink>
              <AnchorLink aria-label='Skills section' className='navbar-item navbar-item--menu-link' href='#skills' onClick={this.toggleActiveState}>Skills</AnchorLink>
            </Navbar.Container>
          </Navbar.Menu>
        </Container>
      </Navbar>
    )
  }
}
