import React, { Component } from 'react'
import Education from './Education'
import Work from './Work'
import Fade from 'react-reveal/Fade'
import { Heading, Section, Container } from 'react-bulma-components'

export default class Resume extends Component {
  render () {
    return (
      <Section id='resume' className='section section--padding' size='medium'>
        <Fade>
          <Container fluid>
            <Heading>Resume</Heading>
            <hr className='has-background-grey-darker' />
            <Education />
          </Container>
        </Fade>
        <Fade>
          <Container fluid>
            <Work />
          </Container>
        </Fade>
      </Section>
    )
  }
}