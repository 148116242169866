import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { Heading, Section, Container, Progress } from 'react-bulma-components'

export default class TimelineView extends Component {
  render () {
    return (
      <Section id='skills' className='section--padding has-background-light' size='medium'>
        <Fade>
          <Container fluid>
            <Heading>Technical Skills</Heading>
            <hr className='has-background-grey-darker'/>
            <Heading subtitle>HTML/HTML5</Heading>
            <Progress max={100} value={86} size='large' />
            <Heading subtitle>Javascript</Heading>
            <Progress max={100} value={82} size='large' />
            <Heading subtitle>ReactJS</Heading>
            <Progress max={100} value={75} size='large' />
            <Heading subtitle>CSS/SCSS</Heading>
            <Progress max={100} value={75} size='large' />
          </Container>
        </Fade>
      </Section>
    )
  }
}
